<template lang="pug">
  v-card
    v-toolbar(:color="theme.colors.primary" dark)
      v-toolbar-title Lista institutii
      div(class="flex-grow-1")
      template
        v-btn(
          :to="{ name: 'institutions.create' }"
          icon
          title="Adauga institutie"
        )
          v-icon add_circle
    v-card-text
      v-text-field(
        v-model="search"
        append-icon="search"
        label="Cauta dupa nume, CUI..."
        single-line
        hide-details
        clearable
      )
    v-data-table(
      :headers="headers"
      :items="institutions"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{ 'items-per-page-options': [5, 20, 25, 50, 100] }"
      class="elevation-1"
      must-sort
    )
      template(#item="{ item }")
        tr
          td
            router-link(:to="{ name: 'institutions.edit', params: { id: item.id } }") {{ item.name }}
          td(class="text-no-wrap") {{ item.uic }}
          td(class="text-no-wrap") {{ item.created_at }}
          td(class="text-no-wrap text-right")
            template(v-if="user.role.name === 'developer'")
              v-btn(
                :href="siteUrl(item)"
                small
                icon
                target="_blank"
                class="mr-2"
                title="Vezi site"
              )
                v-icon(small) public
              v-btn(
                :href="institutionFrameUrl(item)"
                small
                icon
                target="_blank"
                class="mr-2"
                title="Vezi iframe principal"
              )
                v-icon(small) crop
            v-btn(
              :to="{ name: 'institutions.edit', params: { id: item.id } }"
              small
              icon
              class="mr-2"
              title="Editeaza"
            )
              v-icon(small) edit
</template>
<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  data () {
    return {
      loadInstitutionsDebouncer: null,
      loading: false,
      headers: [
        { text: 'Nume', value: 'name' },
        { text: 'CUI', value: 'uic' },
        { text: 'Data adaugarii', value: 'created_at' },
        { text: '', sortable: false }
      ],
      options: {
        page: 1,
        perPage: 10,
        sortBy: ['name'],
        sortDesc: [false]
      },
      search: '',
      total: 0,
      institutions: []
    };
  },
  computed: {
    ...mapState({
      user: s => s.auth.user,
      theme: s => s.config.theme
    })
  },
  watch: {
    search: function () {
      this.loadInstitutionsDebouncer();
    },
    options: {
      handler () {
        this.loadInstitutions();
      },
      deep: true
    }
  },
  created () {
    this.loadInstitutionsDebouncer = _.debounce(this.loadInstitutions, 500);
  },
  methods: {
    institutionFrameUrl (institution) {
      return `${process.env.VUE_APP_APP_URL}/#/public/institutions/${institution.id}`;
    },
    siteUrl (institution) {
      return `${process.env.VUE_APP_APP_URL}/#/public/site/institutions/${institution.id}`;
    },
    loadInstitutions () {
      this.loading = true;

      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy ? this.options.sortBy[0] : null,
        sortDesc: this.options.sortDesc ? (this.options.sortDesc[0] ? 1 : 0) : null,
        search: this.search
      };

      return Vue.axios.get('/institutions', { params })
        .then(res => {
          this.institutions = res.data.data;
          this.total = res.data.meta.pagination.total;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
