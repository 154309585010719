<template lang="pug">
  v-card
    v-toolbar(:color="theme.colors.primary" dark)
      v-btn(
        :to="{ name: 'users.index' }"
        icon
        exact
        title="Inapoi"
      )
        v-icon arrow_back
      v-toolbar-title Editare utilizator
      div(class="flex-grow-1")
      template
        v-btn(
          :to="{ name: 'users.create' }"
          icon
          title="Adauga utilizator"
        )
          v-icon add_circle
    validation-observer(ref="observer" @submit.prevent="sendRequest")
      v-form
        v-row(class="px-5")
          v-col(v-if="loggedInUser.role.name === 'developer'" cols="12")
            validation-provider(name="institution" :rules="form.institution.validation.rules" v-slot="{ errors }")
              v-select(
                v-model="form.institution.value"
                :items="institutions"
                :label="form.institution.label"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                required
              )
          v-col(v-if="loggedInUser.role.name === 'developer'" cols="12")
            validation-provider(name="role" :rules="form.role.validation.rules" v-slot="{ errors }")
              v-select(
                v-model="form.role.value"
                :items="roles"
                :label="form.role.label"
                :error-messages="errors"
                item-text="name"
                item-value="id"
                required
              )
          v-col(cols="12")
            validation-provider(name="name" :rules="form.name.validation.rules" v-slot="{ errors }")
              v-text-field(
                v-model="form.name.value"
                :label="form.name.label"
                :error-messages="errors"
                required
              )
          v-col(cols="12")
            validation-provider(name="email" :rules="form.email.validation.rules" v-slot="{ errors }")
              v-text-field(
                v-model="form.email.value"
                :label="form.email.label"
                :error-messages="errors"
                type="email"
                required
              )
          v-col(cols="12")
            validation-provider(name="phone")
              v-text-field(
                v-model="form.phone.value"
                :label="form.phone.label"
              )
          v-col(cols="12")
            validation-provider(name="password" :rules="form.password.validation.rules" v-slot="{ errors }")
              v-text-field(
                v-model="form.password.value"
                :label="form.password.label"
                :error-messages="errors"
                type="password"
                ref="password"
                required
              )
          v-col(cols="12")
            validation-provider(name="repeat_password" :rules="form.repeat_password.validation.rules" v-slot="{ errors }")
              v-text-field(
                v-model="form.repeat_password.value"
                :label="form.repeat_password.label"
                :error-messages="errors"
                type="password"
                required
              )
        div(class="grey lighten-4 pa-3")
          v-card-actions
            v-spacer
            v-btn(color="success" type="submit") Actualizeaza
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    institutions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      user: null,
      form: {
        institution: {
          value: null,
          label: 'Institutie',
          validation: {
            rules: {
              required: this.requiredDeveloperFields
            }
          }
        },
        role: {
          value: null,
          label: 'Rol',
          validation: {
            rules: {
              required: this.requiredDeveloperFields
            }
          }
        },
        name: {
          value: null,
          label: 'Nume',
          validation: {
            rules: {
              required: true
            }
          }
        },
        email: {
          value: null,
          label: 'Adresa de-email',
          validation: {
            rules: {
              required: true,
              email: true
            }
          }
        },
        phone: {
          value: null,
          label: 'Telefon'
        },
        password: {
          value: null,
          label: 'Parola',
          validation: {
            rules: {}
          }
        },
        repeat_password: {
          value: null,
          label: 'Repeta parola',
          validation: {
            rules: { confirmed: ['password'] }
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      loggedInUser: s => s.auth.user,
      theme: s => s.config.theme
    }),
    requiredDeveloperFields () {
      return this.loggedInUser ? this.loggedInUser.role.name === 'developer' : true;
    }
  },
  mounted () {
    this.loadUser();
  },
  methods: {
    loadUser () {
      this.$store.commit('pageLoading', true);

      return this.axios.get(`/users/${this.id}`)
        .then(res => {
          this.user = res.data.data;

          this.form.institution.value = this.user.institution.id;
          this.form.role.value = this.user.role.id;
          this.form.name.value = this.user.name;
          this.form.email.value = this.user.email;
          this.form.phone.value = this.user.phone;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.$store.commit('pageLoading', false);
        });
    },
    getBody () {
      return {
        institution: this.form.institution.value,
        role: this.form.role.value,
        name: this.form.name.value,
        email: this.form.email.value,
        phone: this.form.phone.value,
        password: this.form.password.value
      };
    },
    sendRequest () {
      return this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.$store.commit('pageLoading', true);

          return this.axios.put(`/users/${this.id}`, this.getBody())
            .then(() => {
              this.$success('Utilizatorul a fost actualizat.');
            })
            .catch(e => {
              if (e.response && e.response.data.status_code === 422 && e.response.data.hasOwnProperty('errors')) {
                this.$refs.observer.setErrors(e.response.data.errors);
              } else {
                this.$error(e);
              }
            }).finally(() => {
              this.$store.commit('pageLoading', false);
            });
        }
      });
    }
  }
};
</script>
