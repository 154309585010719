var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.theme.colors.primary,"dark":""}},[_c('v-btn',{attrs:{"to":{ name: 'users.index' },"icon":"","exact":"","title":"Inapoi"}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_vm._v("Utilizator nou")])],1),_c('validation-observer',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.sendRequest($event)}}},[_c('v-form',[_c('v-row',{staticClass:"px-5"},[(_vm.loggedInUser.role.name === 'developer')?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"institution","rules":_vm.form.institution.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.institutions,"label":_vm.form.institution.label,"error-messages":errors,"item-text":"name","item-value":"id","required":""},model:{value:(_vm.form.institution.value),callback:function ($$v) {_vm.$set(_vm.form.institution, "value", $$v)},expression:"form.institution.value"}})]}}],null,false,3419442250)})],1):_vm._e(),(_vm.loggedInUser.role.name === 'developer')?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"role","rules":_vm.form.role.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.roles,"label":_vm.form.role.label,"error-messages":errors,"item-text":"name","item-value":"id","required":""},model:{value:(_vm.form.role.value),callback:function ($$v) {_vm.$set(_vm.form.role, "value", $$v)},expression:"form.role.value"}})]}}],null,false,2376101962)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":_vm.form.name.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.form.name.label,"error-messages":errors,"required":""},model:{value:(_vm.form.name.value),callback:function ($$v) {_vm.$set(_vm.form.name, "value", $$v)},expression:"form.name.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":_vm.form.email.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.form.email.label,"error-messages":errors,"type":"email","required":""},model:{value:(_vm.form.email.value),callback:function ($$v) {_vm.$set(_vm.form.email, "value", $$v)},expression:"form.email.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"phone"}},[_c('v-text-field',{attrs:{"label":_vm.form.phone.label},model:{value:(_vm.form.phone.value),callback:function ($$v) {_vm.$set(_vm.form.phone, "value", $$v)},expression:"form.phone.value"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"password","rules":_vm.form.password.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"password",attrs:{"label":_vm.form.password.label,"error-messages":errors,"type":"password","required":""},model:{value:(_vm.form.password.value),callback:function ($$v) {_vm.$set(_vm.form.password, "value", $$v)},expression:"form.password.value"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"repeat_password","rules":_vm.form.repeat_password.validation.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.form.repeat_password.label,"error-messages":errors,"type":"password","required":""},model:{value:(_vm.form.repeat_password.value),callback:function ($$v) {_vm.$set(_vm.form.repeat_password, "value", $$v)},expression:"form.repeat_password.value"}})]}}])})],1)],1),_c('div',{staticClass:"grey lighten-4 pa-3"},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","type":"submit"}},[_vm._v("Adauga")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }