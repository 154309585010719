<template lang="pug">
  v-card
    v-toolbar(:color="theme.colors.primary" dark)
      v-toolbar-title Lista utilizatori
      div(class="flex-grow-1")
      template
        v-btn(
          :to="{ name: 'users.create' }"
          icon
          title="Adauga utilizator"
        )
          v-icon add_circle
    v-card-text
      v-text-field(
        v-model="search"
        append-icon="search"
        label="Cauta dupa nume, e-mail, institutie..."
        single-line
        hide-details
        clearable
      )
    v-data-table(
      :headers="headers"
      :items="users"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{ 'items-per-page-options': [5, 20, 25, 50, 100] }"
      class="elevation-1"
      must-sort
    )
      template(#item="{ item }")
        tr
          td
            router-link(:to="{ name: 'users.edit', params: { id: item.id } }") {{ item.name }}
          td {{ item.email }}
          td {{ item.phone }}
          td {{ item.institution.name }}
          td {{ item.role.name }}
          td(class="text-no-wrap") {{ item.created_at }}
          td(class="text-no-wrap text-right")
            v-btn(
              :to="{ name: 'users.edit', params: { id: item.id } }"
              small
              icon
              class="mr-2"
              title="Editeaza"
            )
              v-icon(small) edit
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  data () {
    return {
      loadUsersDebouncer: null,
      loading: false,
      headers: [
        { text: 'Nume', value: 'name' },
        { text: 'E-mail', value: 'email' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Institutie', value: 'institution.name', sortable: false },
        { text: 'Rol', value: 'role.name', sortable: false },
        { text: 'Data adaugarii', value: 'created_at' },
        { text: '', sortable: false }
      ],
      options: {
        page: 1,
        perPage: 10,
        sortBy: ['name'],
        sortDesc: [false]
      },
      search: '',
      total: 0,
      users: []
    };
  },
  computed: {
    ...mapState({
      theme: s => s.config.theme
    })
  },
  watch: {
    search: function () {
      this.loadUsersDebouncer();
    },
    options: {
      handler () {
        this.loadUsers();
      },
      deep: true
    }
  },
  created () {
    this.loadUsersDebouncer = _.debounce(this.loadUsers, 500);
  },
  methods: {
    loadUsers () {
      this.loading = true;

      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy ? this.options.sortBy[0] : null,
        sortDesc: this.options.sortDesc ? (this.options.sortDesc[0] ? 1 : 0) : null,
        search: this.search
      };

      return this.axios.get('/users', { params })
        .then(res => {
          this.users = res.data.data;
          this.total = res.data.meta.pagination.total;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
