<template lang="pug">
  v-card
    v-toolbar(:color="theme.colors.primary" dark)
      v-btn(
        :to="{ name: 'institutions.index' }"
        icon
        exact
        title="Inapoi"
      )
        v-icon arrow_back
      v-toolbar-title Editare institutie
      div(class="flex-grow-1")
      template
        v-btn(
          :to="{ name: 'institutions.create' }"
          icon
          title="Adauga institutie"
        )
          v-icon add_circle
    validation-observer(ref="observer" @submit.prevent="sendRequest")
      v-form
        v-row(class="px-5")
          v-col(cols="12")
            validation-provider(name="name" :rules="form.name.validation.rules" v-slot="{ errors }")
              v-text-field(
                v-model="form.name.value"
                :label="form.name.label"
                :error-messages="errors"
                required
              )
          v-col(cols="12")
            validation-provider(name="uic" :rules="form.uic.validation.rules" v-slot="{ errors }")
              v-text-field(
                v-model="form.uic.value"
                :label="form.uic.label"
                :error-messages="errors"
                required
              )
          v-col(cols="12")
            validation-provider(name="labelsType" :rules="form.labelsType.validation.rules" v-slot="{ errors }")
              v-select(
                v-model="form.labelsType.value"
                :items="labelsTypes"
                :label="form.labelsType.label"
                :error-messages="errors"
                item-text="name"
                item-value="value"
                required
              )
        div(class="grey lighten-4 pa-3")
          v-card-actions
            v-spacer
            v-btn(color="success" type="submit") Actualizeaza
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    const labelsTypes = [
      {
        value: 0,
        name: 'De baza'
      },
      {
        value: 1,
        name: 'Extins'
      }
    ];

    return {
      labelsTypes,
      institution: null,
      form: {
        name: {
          value: null,
          label: 'Nume',
          validation: {
            rules: {
              required: true
            }
          }
        },
        uic: {
          value: null,
          label: 'CUI',
          validation: {
            rules: {
              required: true
            }
          }
        },
        labelsType: {
          value: null,
          label: 'Tip etichete',
          validation: {
            rules: {
              required: true
            }
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      theme: s => s.config.theme
    })
  },
  mounted () {
    this.loadInstitutions();
  },
  methods: {
    loadInstitutions () {
      this.$store.commit('pageLoading', true);

      return this.axios.get(`/institutions/${this.id}`)
        .then(res => {
          this.institution = res.data.data;

          this.form.name.value = this.institution.name;
          this.form.uic.value = this.institution.uic;
          this.form.labelsType.value = this.institution.labels_type;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.$store.commit('pageLoading', false);
        });
    },
    getBody () {
      return {
        name: this.form.name.value,
        uic: this.form.uic.value,
        labels_type: this.form.labelsType.value
      };
    },
    sendRequest () {
      return this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.$store.commit('pageLoading', true);

          return this.axios.put(`/institutions/${this.id}`, this.getBody())
            .then(() => {
              this.$success('Institutia a fost actualizata.');
            })
            .catch(e => {
              if (e.response && e.response.data.status_code === 422 && e.response.data.hasOwnProperty('errors')) {
                this.$refs.observer.setErrors(e.response.data.errors);
              } else {
                this.$error(e);
              }
            }).finally(() => {
              this.$store.commit('pageLoading', false);
            });
        }
      });
    }
  }
};
</script>
