<template lang="pug">
  v-container
    router-view(:roles="roles" :institutions="institutions")
</template>
<script>
import { mapState } from 'vuex';
export default {
  data () {
    return {
      roles: [],
      institutions: []
    };
  },
  computed: {
    ...mapState({
      user: s => s.auth.user
    })
  },
  mounted () {
    if (this.user.role.name === 'developer') {
      this.$store.commit('pageLoading', true);

      Promise.all([
        this.loadRoles()
          .then(res => {
            this.roles = res.data.data;
          }),
        this.loadInstitutions()
          .then(res => {
            this.institutions = res.data.data;
          })
      ])
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.$store.commit('pageLoading', false);
        });
    }
  },
  methods: {
    loadRoles () {
      return this.axios.get('/roles');
    },
    loadInstitutions () {
      return this.axios.get('/institutions/all');
    }
  }
};
</script>
